import React, { CSSProperties, useCallback, useContext, useState } from "react";
import { navigate } from "gatsby";
import { useDropzone } from "react-dropzone";
import { Context } from "./store";
// import { urlService } from '@/_services';
import { urlService } from "../_services";
import WaitingAnimation from "./WaitingAnimation";
import { addError } from "../utils/errors";

const dropZoneStyle: CSSProperties = {
  width: "100%",
  height: "60px",
  backgroundColor: "white",
  border: "4px dashed lightblue",
  textAlign: "center",
};

function MyDropzone() {
  let [is_waiting, setIsWaiting] = useState(false);
  let [, setContext] = useContext(Context);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setIsWaiting(true);
      var formData = new FormData();
      formData.append("image", acceptedFiles[0]);

      let url = urlService.getUrl("/api/drop");

      urlService
        .getAxios()
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          navigate(`/filecontent/${res.data.id}`, {});
        })
        .catch((err) => {
          setIsWaiting(false);
          if (err.response && err.response.status === 501) {
            alert("Der Upload war erfolgreich, der Rest ist noch nicht implementiert");
          } else {
            const errorMessage = err.response.data.message ? err.response.data.message : "Unbekannter Fehler";
            addError(setContext, `Fehler beim Upload: ${errorMessage}`);
          }
        });
    },
    [setContext]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div style={{ padding: "0px 20px" }}>
      {is_waiting ? (
        <WaitingAnimation />
      ) : (
        <div {...getRootProps()} style={dropZoneStyle}>
          <input {...getInputProps()} />
          {isDragActive ? <p>Drop the files here ...</p> : <p>Ziehen Sie eine Datei hier rein, oder klicken Sie zum Auswählen der Datei</p>}
        </div>
      )}
    </div>
  );
}

export default function Upload() {
  return (
    <div className="py-4">
      <MyDropzone />
    </div>
  );
}
