import React, { Component, ReactChildren, useEffect, useState } from "react";
import { navigate } from "gatsby";
import { authenticationService } from "../../_services/authenticationService";
import { useLocation } from "@reach/router";
import WaitingAnimation from "../WaitingAnimation";

type PropType = {
  referralMessage?: string;
  referralMessageOptOutKey?: string;
  children: any;
};

const PrivateRoute = ({ referralMessage, referralMessageOptOutKey, children, ...rest }: PropType) => {
  const location = useLocation();
  const [state, setState] = useState({ waiting: true });

  useEffect(() => {
    if (!authenticationService.isLoggedIn()) {
      navigate("/login", {
        state: {
          referralUrl: location.pathname,
          referralMessage,
          referralMessageOptOutKey,
        },
      });
      return;
    }

    // Hide the waiting animation if we're here. Show the normal component
    setState({ waiting: false });

    // Set the timeout for the token refresh. Make it recursive
    const time = Math.max(authenticationService.getJWTValidityInSeconds(), 0);
    console.log(`Setting timeout for token refresh in ${time} seconds.`);
    let timer = setTimeout(async function run() {
      const time = Math.max(authenticationService.getJWTValidityInSeconds(), 0);
      await authenticationService.refresh();
      console.log(`Setting recursive timeout for token refresh in ${time} seconds.`);
      timer = setTimeout(run, (time - 60) * 1000);
    }, (time - 60) * 1000);

    return () => clearTimeout(timer);
  }, [location.pathname]);

  // return <div>{state.waiting ? <WaitingAnimation /> : <Component {...rest} />}</div>;
  return <div>{state.waiting ? <WaitingAnimation /> : children}</div>;
};

export default PrivateRoute;
