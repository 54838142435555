import * as React from "react";
import Upload from "../components/Upload";
import PrivateRoute from "../components/user-management/PrivateRoute";

// markup
const DropzonePage = () => {
  return (
    <div>
      <h2>Belegdatei hochladen</h2>
      <div className="text-right">
        {/* We use a instead of Link since gatsby seems not to support this with their component */}
        <a href="/files/rap-tool-beispieldatei.xlsx" download>
          <button className="button">Beispieldatei herunterladen</button>
        </a>
      </div>

      <PrivateRoute
        referralMessage="Diese Funktion steht derzeit nur eingeloggten Nutzern zur Verfügung. Sie können sich kostenlos einen Account erstellen."
        referralMessageOptOutKey="hintUploadOnlyForUsersOptOut"
      >
        <Upload />
      </PrivateRoute>
    </div>
  );
};

export default DropzonePage;
